import { connect } from 'react-redux';
import { Animation } from '../Animation';
import './index.scss';
import { useNavigate } from 'react-router';
import { priceFormat, truncateString, truncateStringByLimitWords } from '../../classes/Helpers';

const LIMIT_LETTERS_TAGS = 16;

const PackContainer = (props) =>{
    var totalLettersTags = 0;

    const navigate = useNavigate()

    const { pack, clickable, showPrice } = props;

    const onClick = () => {
        navigate(`/app/search/pack?pack_id=${props.pack._id}`)
    }
    if(!pack._id){return null}
    return (
        
        <div className='pack-container-outer'>
            <button disabled={clickable != null ? !clickable : false} className='pack-container btn' onClick={onClick}>
                <Animation width={120} image={pack.imageBoxURL}/>

                <div className='text-container'>
                    <h5 className="text-left text-black">{truncateStringByLimitWords(pack.name, 3)}</h5>
                </div>
 <div className='flex' style={{marginTop : 0}}>
                        
                        {!showPrice ? <h6 className='tag-primary'> {pack.cost} credits</h6> : null}

                        {pack.tags.map( (genre, index) => {
                        if(index > 0){return null}
                        totalLettersTags = totalLettersTags + genre.length;
                        if(totalLettersTags > LIMIT_LETTERS_TAGS){return null}
                        return (
                            <h6 key={index} className='tag text-grey'>{genre}</h6>
                        )
                    })}
                    <h6 className='text-grey tag-outline'>{pack.sounds.length} sounds</h6>

                </div>
  {showPrice ? 
                    <div className='flex' style={{marginTop : 0}}>
                        <h5 className='tag-primary'>{priceFormat(pack.cost)}</h5>
                    </div>
                :  <div className='flex' style={{marginTop : 0}}>
                    </div>
                }
               
              
              
            </button>
        </div>
    )
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(PackContainer);
