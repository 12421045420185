
import './index.scss';
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { truncateStringByLimitWords } from '../../classes/Helpers';
const API_KEY = 'c88080441899fc86c2cceda576';

class Blog extends Component{

    constructor(props){super(props); this.state = {posts : []};}

    componentDidMount(){
        this.__getPosts();
    }

    __getPosts = async () => {
        const res = await fetch(`https://vertate.ghost.io/ghost/api/content/posts/?key=${API_KEY}`);
        console.log(res)
        const data = await res.json();
        console.log(data)
        this.setState({posts : data.posts});
    }
    render = () => {
        const { posts } = this.state;
        const isMobile = window.innerWidth < 500;
        const isTablet = window.innerWidth < 768 && window.innerWidth >= 500;
        return (
            <div className='container blog-container'>
                <h2 className='text-primary title'>Join a community of knowledge</h2>
                <h4 className='text-grey'>Get ready to learn everything from A to Z ✌️</h4>
                <div className='flex-container'>
                    <h5 className='tag-outline'>Music Theory</h5>
                    <h5 className='tag-outline'>Techniques</h5>
                    <h5 className='tag-outline'>Mixing</h5>
                </div>
                <div className="blog-list">
                    {posts.map((post, index) => {
                        if(post.visibility !== 'public'){return null;}
                        if(index > 3){return null;}
                        if(isMobile && index > 0){return null;}
                        if(isTablet && index > 1){return null;}
                        return (
                            <a href={post.url} target='__blank' key={index} className="blog-post">
                                <img src={post.feature_image}/>
                                <h3>{post.title}</h3>
                                <h5 className='text-thin text-grey'>{truncateStringByLimitWords(post.excerpt, 30) + "..."}</h5>
                            </a>
                        )
                    })}
                </div>
            </div>
        )
    }
  
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(Blog);