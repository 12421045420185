import { Link, useNavigate } from "react-router-dom";
import './index.scss';
import { createAvatar } from '@dicebear/core';
import { shapes } from '@dicebear/collection';
import { connect } from "react-redux";
import Countdown from "react-countdown";
import CountDownTimer from "../CountDownTimer";



const ButtonSubscribePromotion = (props) => {
    
    const navigate = useNavigate();

    const { user } = props;
    let isAuthenticated = user._id ? true : false;
    var avatar, dataURI = '';
    if(isAuthenticated){
        avatar = createAvatar(shapes, {
            seed: user.email
        }); 
        dataURI = avatar.toDataUriSync();   
    }       

    const endedCountdown = () => {

    }
    const isSubscribed = user.subscription && user.subscription.price;
    const isActive = user.subscription && user.subscription.isActive;
    var timeLeft = new Date(user.register_timestamp) - Date.now() + 24*60*60*1000;
    const showBorderBottom = false;

    return (
        <>
            {!isSubscribed?
                <>
                <Link to={'/app/subscribe'} className="btn-animation btn">
                    <h4 className="text-white text-max text-bold">
                        {(timeLeft > 0) ? '60% OFF TODAY' : 'Try for FREE for 7 days'}</h4>
                </Link>
                {timeLeft > 0 ?
                    <div className="offer-time not-mobile">
                        <h6 className="text-white text-max inline">Offer ends in</h6>
                        <h4 className="text-white text-max inline" style={{marginLeft:5}}>
                            <CountDownTimer
                                id={'promotion-credits'}
                                date={Date.now() + timeLeft}
                                endedCountdown={endedCountdown}
                            />
                        </h4>
                    </div>
                    : <div className="offer-time not-mobile">
                        <h6 className="text-white text-max" style={{marginTop : 6}}>GET 250 FREE Credits</h6>
                    </div>}
                </>
                : null}
        </>
    )
  
}

function mapStateToProps(state) {
    return {
        user :state.user
    };
  }

  export default connect(mapStateToProps)(ButtonSubscribePromotion);