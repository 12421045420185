import './index.scss';
import { connect } from 'react-redux';
import { RocketLaunch, BikeScooterOutlined, GraphicEqOutlined, SupportOutlined, CardGiftcard,LoyaltyOutlined, AlbumOutlined, EarbudsOutlined,  PianoOutlined, PriceCheckOutlined, TollOutlined, VerifiedUser, Fingerprint, LockClock } from '@mui/icons-material';
import { STRIPE_CODE, SUBSCRIPTION_PRODUCER_URL, SUBSCRIPTION_STARTER_URL } from '../../../../../config';
import { logo_color, logo_grey } from '../../../../../assets/images';
import { Link } from 'react-router-dom';

const PricingBox = (props) => {
    const user = props.user;
    const isSigned = (user && user.email) ? true : false;
    const {card} = props;
    var timeLeft = 0;
    var isInPromotion = false;
    var stringStripeCode = '';

    if(isSigned){
        timeLeft = new Date(user.register_timestamp) - Date.now() + 24*60*60*1000;
        isInPromotion = timeLeft > 0;
        stringStripeCode = `&prefilled_promo_code=${STRIPE_CODE}`;
    }


    const priceRenderDiscount = ({integer, decimals}) => {
         return (
            <div className='price-container'>
                <div className='inline'>
                    <h3 className='text-primary decimal'>$</h3>
                </div>
                <h1 className='inline text-primary'>{integer}</h1>
                <div className='inline'>
                    <h4 className='text-primary decimal'>{decimals}</h4>
                    <h4 className='text-primary decimal'>/mo</h4>
                </div>
            </div>
        )
    }

    const prevPriceRender = ({integer, decimals}) => {
         return (
            <div className='price-container'>
                <div className='inline'>
                    <h5 className='text-grey decimal text-striked'>$</h5>
                </div>
                <h5 className='inline text-grey text-striked'>{integer}</h5>
                <div className='inline'>
                    <h5 className='text-grey decimal text-striked'>{decimals}</h5>
                    <h5 className='text-grey decimal text-striked'>/mo</h5>
                </div>
            </div>
        )
    }

    const priceRender = ({integer, decimals}) => {
        return (
            <div className='price-container'>
                <div className='inline'>
                    <h3 className='text-grey decimal'>$</h3>
                </div>
                <h1 className='inline'>{integer}</h1>
                <div className='inline'>
                    <h4 className='text-grey decimal'>{decimals}</h4>
                    <h4 className='text-grey decimal'>/mo</h4>
                </div>
            </div>
        )
    }

    const renderTagPromotion = ({icon, text, smallText, soon}) => {
        return (
            <div className='flex-container tag-container-price promotion-tag'>
                {icon}
                <h4 className='text-desc'>{text}<span className='span-desc'>{smallText}</span></h4> 
            </div>
        )
    }

    const renderTag = ({icon, text, smallText, soon}) => {
        return (
            <div className='flex-container tag-container-price'>
                {icon}
                <h4 className='text-desc'>{text}<span className='span-desc'>{smallText}</span></h4> 
                {soon ? <h5 className='tag tag-cont'>Soon</h5> : null}
            </div>
        )
    }

    const renderCardOne = () => {
        return (
            <div className='subscription-card'>
                <div className='sub-container'>
                    <img src={logo_grey} className='icon' />
                    <h2>Starter</h2>
                </div>       
                {isInPromotion ? 
                    <>
                    {priceRenderDiscount({integer : 7, decimals : ",97"})}
                    {prevPriceRender({integer : 19, decimals : ",99"})}
                    </>
                :  
                    priceRender({integer : 19, decimals : ",99"})
                }
                <div className='description-container'>
                    {renderTag({
                        icon : <GraphicEqOutlined className='icon-desc'/>,
                        text : "100,000 Sounds",
                        smallText : "Access"
                    })}
                    {renderTag({
                        icon : <TollOutlined className='icon-desc'/>,
                        text : "450 Credits",
                        smallText : "Every Month"
                    })}
                    {renderTag({
                        icon : <VerifiedUser className='icon-desc'/>,
                        text : "100% Royalty Free",
                        smallText : "Forever"
                    })}
                    {renderTag({
                        icon : <SupportOutlined className='icon-desc'/>,
                        text : "Customer Support",
                        smallText : "Limited"
                    })}
                    {renderTagPromotion({
                        icon : <CardGiftcard className='icon-desc'/>,
                        text : "25 Credits",
                        smallText : "Bonus"
                    })}
                    <a href={!isSigned ? '/signup':`${SUBSCRIPTION_STARTER_URL}?prefilled_email=${user.email}` + stringStripeCode} className='btn btn-sub'>
                        <h4>Subscribe</h4>
                    </a>
                    <h5 className='text-grey'>Try for Free for 7 Days</h5>
                </div>
            </div>
        )
    }

    const renderCardTwo = () => {
        return (
        <div className='subscription-card best-deal'>
            <div className='sub-container'>
                <img src={logo_color} className='icon' />
                <h2>Producer</h2>
            </div>      
            {isInPromotion ? 
                    <>
                    {priceRenderDiscount({integer : 27, decimals : ",97"})}
                    {prevPriceRender({integer : 49, decimals : ",99"})}
                    </>
                :  
                    priceRender({integer : 49, decimals : ",99"})
            }
            <div className='description-container'>
                {renderTag({
                    icon : <GraphicEqOutlined className='icon-desc'/>,
                    text : "+2,000,000 Sounds",
                    smallText : "Access"
                })}
                {renderTag({
                    icon : <TollOutlined className='icon-desc'/>,
                    text : "2000 Credits",
                    smallText : "Every Month"
                })}
                {renderTag({
                    icon : <VerifiedUser className='icon-desc'/>,
                    text : "100% Royalty Free",
                    smallText : "Forever"
                })}
                {renderTag({
                    icon : <Fingerprint className='icon-desc'/>,
                    text : "Pack Generator",
                    smallText : "AI powered",
                    soon : true
                })}
                {renderTag({
                    icon : <EarbudsOutlined className='icon-desc'/>,
                    text : "VertPRO Mastering",
                    smallText : "VST",
                    soon : true
                })}
                {renderTag({
                    icon : <PianoOutlined className='icon-desc'/>,
                    text : "VertPRO Synth",
                    smallText : "VST",
                    soon : true
                })}
                {renderTag({
                    icon : <SupportOutlined className='icon-desc'/>,
                    text : "Customer Support",
                    smallText : "7 Days/Week"
                })}
                {renderTagPromotion({
                    icon : <CardGiftcard className='icon-desc'/>,
                    text : "250 Credits",
                    smallText : "Bonus"
                })}
                <a href={!isSigned ? '/signup' : `${SUBSCRIPTION_PRODUCER_URL}?prefilled_email=${user.email}`+ stringStripeCode} className='btn btn-primary btn-sub'>
                    <div className='btn-subscribe'>
                        <LockClock className='icon-desc'/>
                        <h4>Subscribe</h4>
                    </div>
                </a>
                <h5 className='text-grey'>Try for Free for 7 Days</h5>
            </div>
        </div>
        )
    }

    if(props.card === 1){return renderCardOne()}
    if(props.card === 2){return renderCardTwo()}
}


function mapStateToProps(state) {
    return {
        user : state.user
    };
  }

 export default connect(mapStateToProps)(PricingBox);
