import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import SearchPage from "../SearchPage";
import GoogleAnalyticsSingleton from '../../../classes/GoogleAnalytics';
import { MetaTags } from 'react-meta-tags';

class FavoritesPage extends Component{
    constructor(props){super(props);this.state = { value : 0}}


    componentDidMount = async () => {   
        GoogleAnalyticsSingleton.pageview('favorites');
    }

    onChangeTab = (newTab) => {
        this.setState({value : newTab});
    }

    render = () => {
        return (
            <>
                <MetaTags>
                    <title>Likes</title>
                    <meta name="description" content="Don´t lose access to the sounds & packs you liked" />
                    <meta property="og:title" content="My Likes" />
                </MetaTags>
                <SearchPage myFavorites={true}/>
            </>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(FavoritesPage);
