import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import Modal from '../Modal';
import { Link } from 'react-router-dom';
import { CheckBoxOutlineBlank, CheckCircle, FavoriteOutlined } from '@mui/icons-material';
import { SUBSCRIPTION_STARTER_URL } from '../../../config';

class ModalSubscribe extends Component{
    constructor(props){
        super(props)
        this.state = {
            isOpen : false,
            item : {}
        }
        // Create a ref for the Modal component
        this.modalRef = React.createRef();
    }

    componentWillReceiveProps(props){
        const { notification } = props;
        if(notification.code == 12){
            // Not enough credits
            this.setState({isOpen : true, item : notification.item, action : 'add'});
            this.open();
        }
    }

      open = () => {
        if (this.modalRef.current) {
            this.modalRef.current.open();
        }
    };

    renderTag = ({icon, text, description, soon}) => {
        return (
            <div className='tag-container-sub'>
                <div className='tag-container-subscribe-modal'>
                    {icon}
                    <h4 className='text-desc text-left'>{text}</h4> 
                    {soon ? <h5 className='tag tag-cont'>Soon</h5> : null}
                </div>
                <h5 style={{marginTop : 3}} className='text-grey text-left'>{description}</h5>
            </div>
           
        )
    }

    render = () => {
        var { isOpen, item, action } = this.state;
        item = item.pack ? {...item, isSound : true} : item;
        return (
			<Modal middle={true} ref={this.modalRef} {...this.props} >
                <div className='modal-subscribe text-center'>
                    <h2 className='text-center'>Susbcribe to {action} this {item.isSound ? "sound" : "pack"}</h2>
                    <h4 className='text-center text-grey'>Start your 7-day free trial</h4>
                    <img src={item.imageUrl}></img>
                    <h4 className='text-center text-grey'>{item.name}</h4>
                    <div className='container-tags-subscription'>
                        {this.renderTag({
                            icon : <CheckCircle className='icon-desc'/>,
                            text : "Millions of high quality sounds - 100% Royalty free",
                            description : "Get 250 Credits every month to download, all sounds available were totally generated with AI, no sampling or layering "
                        })}
                        {this.renderTag({
                            icon : <CheckCircle className='icon-desc'/>,
                            text : "Best price in the market, No commitments",
                            description : "Each sound costs you $0.001, 50x cheaper than alternatives, cancel your subscription at any time-no questions asked"
                        })}

                    </div>
                    <a href={SUBSCRIPTION_STARTER_URL} target='__blank' className='btn btn-primary btn-sub btn-modal'>
                        <h4 className='text-center'>Subscribe for FREE for 7 Days</h4>
                    </a>
                    <h5 className='text-grey' style={{marginTop : 10}}>
                        Cancel Anytime
                    </h5>
                </div>
		    </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        notification : state.notification
    };
  }

// Use React.forwardRef to forward the ref
export default connect(mapStateToProps)(ModalSubscribe);
