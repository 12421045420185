import { Component } from 'react';
import './index.scss';
import { Footer } from '../../../components';
import HeaderContainer from '../../LandingPage/HeaderContainer';
import { MetaTags } from 'react-meta-tags';
import SubscriptionBox from '../../AppPage/SubscriptionBox';
import PricingBox from '../../AppPage/SubscriptionBox/components/PricingBox';

class Pricing extends Component{

    constructor(props){
        super(props);
        this.state = {packs : []}
    }

    componentDidMount(){

    }

    render = () => {
        return (
            <div className='pricing-page'>   
                 <MetaTags>
                    <title>Vertate | Pricing</title>
                    <meta property="og:title" content="Pricing" />
                    <meta name="description" content="Get Started for Free" />
                </MetaTags>
                <HeaderContainer/>
                <div className='text-container text-center'>
                    <h1 className='text-primary'>Pricing</h1>
                    <h4 className='text-grey'>
                        Our pricing is simple and straightforward. You can get started for free and upgrade as you grow.
                    </h4>
                </div>
               
                <div className='sd'>
                    <div className='pricing-container'>
                        <PricingBox card={1}/>
                        <PricingBox card={2}/>
                    </div>
                </div>
                <Footer width={100} full={false}/>
            </div>
            )
        }
    
}

export default Pricing;