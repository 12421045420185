import './index.scss';
import { Footer } from "../../components";
import MainContainer from './MainContainer';
import { Component } from 'react';
import { connect } from 'react-redux';
import UrlNavigatorSingleton from '../../classes/UrlNavigator';
import Loading from '../../components/Loading';
import HeaderContainer from './HeaderContainer';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import Testimonials from './Testimonials';
import ForthPage from './ForthPage';
import APIConnection from '../../api';
import { MetaTags } from 'react-meta-tags';
import Blog from '../../components/Blog';

class LandingPage extends Component{
    constructor(props){super(props);this.state = {packs : [],totalSounds : 100, totalPacks : 100, loading : true}}
    

    componentDidMount(){
        this.setPacksUI();
    }

    setPacksUI = async () => {
        let res = await APIConnection.getPacks({filter : {size : 15}});
        let res_2 = await APIConnection.getSounds({filter : {size : 60}});
        const { message, status } = res;
        if(status !== 200){
            console.log("res", res);
        }else{
            this.setState({
                loading : false,
                packs : message.data, 
                totalSounds : res_2.message.total,
                totalPacks : message.total, 
            });
        }
    }


    render = () => {
        const { packs, totalPacks, totalSounds, loading } = this.state;
        
        return (
            <>  
                <>{ (!packs.length || loading) ? <Loading/> : null}</>
                <MetaTags>
                    <title>Vertate | Unlimited Creativity</title>
                    <meta name="description" content="Access an Exclusive Library of Sounds" />
                    <meta property="og:title" content="Vertate" />
                </MetaTags>
                {/* Header */}
                <HeaderContainer/>
                {/* Main Page */}
                <MainContainer packs={packs} totalSounds={totalSounds} totalPacks={totalPacks}/>
                {/* Forth Page */}
                <ForthPage/>
                {/* Second Page */}
                <SecondPage/>
                {/* Blog */}
                <Blog/>
                {/* Testominals */}
                <Testimonials/>
                {/* Third Page */}
                <ThirdPage packs={packs}/>
                {/* Footer */}
                <Footer width={100} full={false}/>
            </>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(LandingPage);
