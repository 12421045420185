import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import PackContainer from '../PackContainer';
import Marquee from "react-fast-marquee";

class FastMarquee extends Component{
    constructor(props){super(props)}


    render = () => {
        const { packs, clickable } = this.props;
        if(!packs)return null;

        return (
                <Marquee pauseOnHover={true}>
                    {packs.map((pack, index) => {
                        return (
                            <PackContainer clickable={clickable} pack={pack} key={index} showPrice={true}/>
                        )
                    })}
                </Marquee>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(FastMarquee);
