import './index.scss';
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

class Banner extends Component{

    constructor(props){super(props); this.state = {};}

    render = () => {
        return (
            <div className="banner box-container">
                <div className="banner__content">
                    <h2 className="banner__titl text-left">Welcome to the world of affordable co-producing 👋</h2>
                    <p className="text-grey text-left">Find sounds, discover samples, search for loops in this endless library</p>
                        <div className='container-action'>
                        <div className='action-container-home'>
                            <Link to={'/app/search'}className="btn btn-modal btn-sub btn-animation">
                                <h4>Search +100,000 sounds</h4>
                            </Link>
                        </div>
                        </div>
                    
                </div>
            </div>
        )
    }
  
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(Banner);