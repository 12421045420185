import { Component } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import SkeletonContainer from '../../../../components/SkeletonContainer';
import ListContainer from '../../../../components/ListContainer';
class SoundContainer extends Component{
    
    constructor(props){
        super(props);
    }

    componentDidMount(){

    }


    render = () => {
        const { sounds } = this.props;
        console.log(sounds)
        return (
            <>  
              {sounds && sounds.length > 0 ? 
                    <div className='sounds-container-promo'>
                        <h2 className='text-secondary text-center'>Dive in a Library with over <strong>+100.000 Sounds</strong> 👨‍🚀</h2>
                        <h3 className='text-grey text-center text-thin'>Exclusive Sounds Every Day</h3>
                        <div className='promo-sounds'>
                            <div className='blur-container'></div>
                            <ListContainer total={123230} pages={3} page={1} sounds={sounds}/>
                        </div>
                    </div>
                : <SkeletonContainer type={'list'} count={20}/>}
            </>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(SoundContainer);
