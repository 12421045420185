import { Component } from 'react';
import './index.scss';
import Arrow, { DIRECTION } from 'react-arrows'
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import APIConnection from '../../../api';
import { Album, BlurOn, ClearAll, Cloud, CreditCardOff, Curtains, Fingerprint, Hive, MusicVideoOutlined, VerifiedUser } from '@mui/icons-material';

class ForthPage extends Component{

    constructor(props){
        super(props);
        this.state = {data : []}
    }

    componentDidMount(){
    }

    renderContainer = ({text, title, icon}) => {
        return (
            <div className='text-left container-outline solutions-container'>
                <div className='flex-container'>
                    {icon}
                    <h3 className='text-primary'>{title}</h3>
                </div>
                <h4 className='text-grey'>{text}</h4>
            </div>
            )
    }

    render = () => {
        return (
            <div className='forthpage'>
                <div className='container-solutions text-center'>
                    {this.renderContainer({
                        title : 'Unique & Unlimited',
                        text : 'Neverending Access to thousands of unique sounds, loops and samples',
                        icon : <Fingerprint/>
                    })}
                    {this.renderContainer({
                        title : 'Powered by AI',
                        text : 'Our models will help you find the perfect sound with an easy framework',
                        icon : <Curtains/>
                    })}
                    {this.renderContainer({
                        title : '100% Royalty Free',
                        text : 'All the sounds are made from scratch with no sampling',
                        icon : <VerifiedUser/>
                    })}
                    {this.renderContainer({
                        title : 'Free to Start',
                        text : 'No Credit Card Required. Start for free and upgrade as you grow',
                        icon : <CreditCardOff/>
                    })}
                </div>
            </div>
            )
        }
    
}

export default ForthPage;