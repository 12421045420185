import { Component } from 'react';
import './index.scss';
import Arrow, { DIRECTION } from 'react-arrows'
import { logo_color } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import DarkSwitch from '../../../components/DarkSwitch';

class HeaderContainer extends Component{

    constructor(props){
        super(props);
    }


    render = () => {

        const {justLogo} = this.props;

        return (
            <div className='header-landing'>
                <div className='flex-container vertical-container text-left'>
                    {justLogo ? <></> : 
                        <>
                            <Link to={'/pricing'}><h4 className='link-header not-mobile link'>Pricing</h4></Link>
                            <a target='__blank' href={'https://blog.vertate.com'}><h4 className='link-header not-mobile'>Blog</h4></a>
                            <a target='__blank' href={'https://support.vertate.com'}><h4 className='link-header not-mobile'>FAQ</h4></a>
                        </>
                    }
                </div>
                
                <div><img src={logo_color}/></div>

                <div className='flex-container vertical-container text-right'>
                    {justLogo ? <></> : 
                        <>
                            <DarkSwitch/>
                            <Link to={'/signup'} className='btn btn-outlined'><h4 className='text-bold'>Sign In</h4></Link>
                        </>
                    }
                </div>
            </div>
            )
        }
    
}

export default HeaderContainer;