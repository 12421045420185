import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { logo_color, logo_grey } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import { addOneMonth, formatNumberWithCommas } from '../../../classes/Helpers';
import { STRIPE_PORTAL_LINK } from '../../../../config';
import { ArrowOutwardOutlined } from '@mui/icons-material';
import { MetaTags } from 'react-meta-tags';
import UserAPI from '../../../classes/User';
import NotificationHandlerSingleton from '../../../classes/NotificationHandler';

class AccountPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            value : 0
        }
    }

    

    renderInfo = ({key, value}) => {
        return (
            <div className='fifty'>
                <div>
                    <h5 className='text-grey' style={{margin : 0}}> {key}</h5>
                </div>
                <div className='text-right'>
                    <h4 className=''> {value}</h4>
                </div>
            </div>
       
        )
    }

    recoverPassword = async () => {
        await UserAPI.requestResetPassword({email : this.props.user.email});
        // Inform on Modal
        NotificationHandlerSingleton.onNotification({message : "Password Recovery Email Sent", status : "success"})
    }

    renderNotActive = () => {
        return (
            <div className='fifty'>
                <div>
                    <h5 className='error-text' style={{margin : 0}}> Subscription is not Active </h5>
                </div>
            </div>
       
        )
    }

    renderSubscription = () => {
        const { subscription } = this.props.user;
        const isActive = subscription && subscription.isActive;

        return (
                <>
                  
                    <div className='flex-container sub-cont'>
                        <img src={subscription.image}></img>
                        <div>
                            <h3 className='subscription-name'>{subscription.name}</h3>
                            <h5 className='text-grey'>${formatNumberWithCommas(subscription.price/100)}/mo</h5>
                        </div>      
                        {!isActive ? 
                            <div className='div-upgrade'>
                                <a href={STRIPE_PORTAL_LINK} target='__blank' className='btn btn-secondary flex'>
                                    <h4>Update Billing</h4>
                                    <ArrowOutwardOutlined className='icon-small' style={{marginTop : -4}}/>
                                </a>
                            </div>
                        :  <div className='div-upgrade'>
                                <a href={STRIPE_PORTAL_LINK} target='__blank' className='btn flex' style={{width : 75}}>
                                    <h4>Billing </h4>
                                    <ArrowOutwardOutlined className='icon-small' style={{marginTop : -4}}/>
                                </a>
                            </div>
                        }
                    </div>
                    <div className='container-box'>
                        {this.renderInfo({key : 'Monthly Credits', value : subscription.credits})}
                        {this.renderInfo({key : 'Next Billing', value : addOneMonth(new Date(subscription.last_renew)).toDateString()})}
                        {!isActive ? this.renderNotActive() : null}
                    </div>
                </>
            )
    }

    renderToSubscribe = () => {
        return (
                <>
                    <div className='flex-container'>
                        <img style={{opacity : 0.5}} src={logo_grey}></img>
                        <div>
                            <h3>Newbie</h3>
                            <h4 className='text-grey'>Free</h4>
                        </div>   
                        <div className='div-upgrade'>
                            <Link to={'/app/subscribe'} className='btn btn-primary'>
                                <h4>Upgrade</h4>
                            </Link>
                        </div>
                    </div>
                    <div className='container-box'>
                        {this.renderInfo({key : 'Monthly Credits', value : "0"})}
                    </div>
                </>
            )
    }

    render = () => {
        const { user } = this.props;
        if(!user._id){return null};
        const isSubscribed = user.subscription && user.subscription.price;
        const isActive = user.subscription && user.subscription.isActive;

        return (
            <>
                <MetaTags>
                    <title>Account</title>
                    <meta name="description" content="Manage your Account" />
                    <meta property="og:title" content="Account" />
                </MetaTags>
                <div className='fifty-container'>
                    
                    <div className='normal-container'>
                        {/* User Data*/}
                        {this.renderInfo({key : 'Name', value : user.name})}
                        {this.renderInfo({key : 'Email', value : user.email})}
                        {this.renderInfo({key : 'User ID', value : user._id})}
                        <div className='container-box'>
                            <div className='fifty'>
                                <div>
                                    <h4 className='inline text-grey' style={{margin : 0}}> Change Password </h4>
                                </div>
                                <a style={{cursor : 'pointer'}} onClick={this.recoverPassword} className='text-right'>
                                    <h4 className='inline blue-text'> Password Recovery </h4>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='normal-container subs-box-item'>
                        {/* Subscription Data*/}
                        {isSubscribed ? this.renderSubscription() : this.renderToSubscribe()}
                    </div>
                </div>       
            </>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(AccountPage);
